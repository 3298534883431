<template>
    <div>
        <v-card color="white" :elevation="elevate" :height="height">
            <slot name="divider"></slot>
            <v-card-text >
                <slot name="item"></slot>

            </v-card-text>
        </v-card>
        <div class="search-title">
            <v-card-title class="title-font">{{title}}
                <v-spacer></v-spacer>
                <slot name="icon"></slot>
            </v-card-title>
        </div>
        <v-divider></v-divider>
    </div>
</template>

<script>
    export default {
        inject:["Bus"],
        name: "SearchArea",
        props:{
            title:{
                type:String,
                require:true,
            },
            height:{
                type:String,
                require: true

            },
            elevate:{
                type:Number,
                default:1
            }


        },
        data() {
            return {
                location: ''
            }
        },
        watch: {
            location:function(newValue, oldValue) {
                this.Bus.$emit("search-input",newValue);
            }
        },
        methods: {
            searchInput() {
                this.Bus.$emit("search-input",this.location);
            }
        },
    }
</script>

<style scoped>
    .remove-padding{
        padding: 0 !important;
    }
    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 2px !important;
    }
    .search-title{
        height: 45px;
        margin: 10px;
        padding:10px;
    }
    .title-font{
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        /*font-size: 10px;*/
    }

</style>