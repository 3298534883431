<template>
    <div>
        <v-container v-if="container">
                <div class="property-type-title">
                    <v-subheader class="sub-head">{{title}}</v-subheader>
                </div>
        </v-container>
        <div class="property-type-title" v-if="!container">
            <p>{{title}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchSectionTitle",
        props:["title","container","icon"]
    }
</script>

<style scoped>
    .property-type-title{
        padding:5px ;
        margin-top:-15px;
        color:#379392;
    }
    .property-type-title p{
        /*font-size: 13px;*/
    }
</style>